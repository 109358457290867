#poll-form {
	background-color: #fff !important;

	.card-part-right {
		min-height: 100vh;
		border-left-width: 4px;
		border-left-style: solid;
	}

	.panel {
		margin-bottom: 20px;
		background-color: #fff;
		border: 1px solid transparent;
		border-radius: 4px;
		-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	}

	.panel-card {
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
		border-style: solid;
		.panel-heading {
			border-radius: 0 !important;
		}
	}

	.panel-heading {
		padding: 0px 15px;
		border-bottom: 1px solid transparent;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
	}

	.panel-body {
		padding: 15px;
	}

	.action-button {
		display: flex;
		align-items: center;
		padding: 8px;
		min-width: 120px;
		border-radius: 3px;
		background: var(--feedbalia-color-primary-500);
		color: white;
		font-weight: bold;
		border: none;
		&:hover {
			cursor: pointer;
		}
	}

	.header-poll {
		background: var(--feedbalia-color-primary-500);
		border-radius: 10px;
	}
	.elementHead {
		color: var(--feedbalia-color-primary-500);
		font-size: 150%;
	}

	.background-blue {
		background-image: url("/assets/images/backgrounds/syrsa-wallpaper.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.tituloAzul {
		color: var(--feedbalia-color-primary-700);
	}
}
