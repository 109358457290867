textarea {
	width: 90% !important;
	height: 80px !important;
}

.comment-container {
	padding: 10px;
	width: 95%;
	.comment {
		background-color: #d3d3d378;
		padding: 5px 20px;
		border: 1px lightgray solid;
		border-radius: 5px;
		height: 100%;
		width: 100%;
	}
}

.elementHead {
	color: var(--feedbalia-color-primary-500);
	font-size: 150%;
}
